.captcha-box {
  border: 2px solid var(--Gold-stroke, #feeac3);
  border-right: 0;
  height: 100%;
}
.captcha-box button {
  margin: 0;
  border: 3px;
  margin-left: -100px;
  width: 100px;
  height: 55px;
  cursor: pointer;
  opacity: 0;
}

.captcha-box canvas {
  height: 59.5px !important;
}

@media screen and (max-width: 768px) {
  .captcha-box canvas {
    height: 32px !important;
  }
}
